import React from "react";
import { RaisedButton } from "../Buttons";
import Clock from "../Clock";
import "./Home.scss";

let deadline = "2024-04-16 06:01:00.000Z";

const Home = () => {
  return (
      <div className="home">
        <div className="headerContainer">
          <h1 id="Title"> IKI DEMOKRATIJOS ŽINIŲ KONKURSO LIKO: <Clock deadline={deadline} /></h1>
          <Clock id="clockD" deadline={deadline} />
          {/*<RaisedButton to="/egzaminas/egzaminuotojas">Dalyvauti</RaisedButton>*/}
        </div>
      </div>
  );
}

export default Home;
