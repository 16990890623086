import React from "react";
import SEIMAS from "../../assets/images/lrs.png";
import LRT from "../../assets/images/lrt.png";
import DIENYNAS from "../../assets/images/dienynas.png";
import APROTAS from "../../assets/images/auksinis protas.png";
import NATO from "../../assets/images/NATO.svg";
import "../Footer/Footer.scss";

function Footer() {
  return (
    <div className="footer" id="CTA">
      <div className="topSpon">
        <h2>Turite klausimų?</h2>
        <span>Klauskite el. paštu <a href="mailto:konkursas@lrs.lt">konkursas@lrs.lt</a></span>
      </div>
      <div className="draugSpon"> 
        <div className="draugLogo">
          <a href = "https://www.lrs.lt/"target="_blank" rel="noreferrer">
            <img src={SEIMAS} alt="Lietuvos Respublikos Seimo logotipas"/>
          </a>
          <span>Organizatorius</span>
        </div>
        <div className="draugLogo">
          <a href = "https://www.lrt.lt/"target="_blank" rel="noreferrer">
            <img src={LRT} alt="LRT logotipas"/>
          </a>
          <span>Informacinis partneris</span>
        </div>
        <div className="draugLogo">
          <a href = "https://www.nato.int/"target="_blank" rel="noreferrer">
            <img src={NATO} alt="NATO"/>
          </a>
          <span>Draugas</span>
        </div>
        <div className="draugLogo">
          <a href = "https://www.manodienynas.lt//"target="_blank" rel="noreferrer">
            <img src={DIENYNAS} alt="Mano dienyno logotipas"/>
          </a>
          <span>Draugas</span>
        </div>
        <div className="draugLogo">
          <a href = "https://www.auksinisprotas.lt/"target="_blank" rel="noreferrer">
            <img src={APROTAS} alt="Auksinio proto logotipas"/>
          </a>
          <span>Draugas</span>
        </div>
      </div> 
    </div>
  );
}

export default Footer;
