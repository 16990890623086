import React from "react";
import Layout from "../Components/Layout";
import Home from "../Components/main_page/Home";
import Info from "../Components/main_page/Info";
import FooterAdd from "../Components/main_page/Footer_addon"

const Pagrindinis = () => {
  return (
    <Layout>
    <div className="App">
        {/*<Header />*/} 
        <Home />
        <Info />
        <FooterAdd />
    </div>
    </Layout>
  );
}

export default Pagrindinis;