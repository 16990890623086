import React from "react";
import "./Info.scss";

function Info() {
    return (
        <div className="info">
           <div className="Kada">
                <h2>Kada?</h2>
                <h3>2024 metų <br/>
                    balandžio 16-17 d.</h3>
           </div>
           <div className="Prizai">
                <h2>Prizai</h2>
                <p>
                <b>Moksleiviams</b><br/>
                I vieta – Nešiojamas kompiuteris MacBook Pro 14" M3, <br/>
                II vieta – Iphone Pro Max 15,<br/>
                III vieta – Ipad Pro 11.<br/>
                IV-XIV vietos – Kelionė į NATO būstinę Briuselyje.<br/>
                <br/>
                <b>Suaugusiems</b><br/>
                Geriausiai pasirodžiusieji bus apdovanoti Seimo suvenyrais.
                </p>
           </div>
           <div className="Kas">
                <h2>Kas tai?</h2>
                <p>
                Demokratijos žinių konkursas – trečią kartą organizuojamas 9–12 klasių mokinių ir suaugusiųjų pilietinio raštingumo žinių patikrinimas, į kurį kviečia renginio globėja Lietuvos Respublikos Seimo Pirmininkė Viktorija Čmilytė-Nielsen ir konkurso organizatorė Seimo kanceliarija.<br/>
                <br/>
                Konkursu siekiama paskatinti aktyviau domėtis Lietuvos ir pasaulio politiniu gyvenimu, įgyti daugiau žinių apie įstatymų leidžiamosios institucijos veiklą ir sprendimų priėmimo procesą, demokratijos pagrindus, valstybės ir parlamentarizmo istoriją. Juk išmanydami, kaip yra valdoma valstybė, ir žinodami demokratijos teikiamas galimybes būsime aktyvūs savo šalies piliečiai.
                </p>
           </div>
           <div className="Dalyvauti">
                <h2>Kas gali dalyvauti?</h2>
                <p>
                Demokratijos žinių konkursas skirtas suaugusiems ir 9–12 klasių moksleiviams, tačiau jame gali dalyvauti visi bendrojo ugdymo mokyklų moksleiviai ir suaugusieji.
                </p>
           </div>
           <div className="Kaip">
                <h2>Kaip vyks?</h2>
                <p>
                Konkursas moksleiviams vyks dviem etapais, suaugusiems – vienu etapu.<br/>
                <br/>
                Pirmasis etapas vyks 2024 m. balandžio 16–17
                dienomis nuotoliniu būdu interneto puslapyje dzk.lrt.lt. Jį sudarys testo tipo klausimų sprendimas.<br/>
                <br />
                Pirmojo etapo laimėtojais bus pripažinti dalyviai, kurie pateiks daugiausia teisingų atsakymų į testo tipo uždarus klausimus ir išspręs konkurso užduotis per trumpiausią laiką. Į finalinį renginį pateks 60 pirmajame etape geriausiai pasirodžiusių moksleivių etapo dalyvių.<br/>
                <br/>
                Finalinis etapas vyks Seimo rūmuose 2024 m. gegužės 11 d. Šį etapą sudarys testo tipo klausimų sprendimas ir Seimo darbo simuliacija. Konkurso laimėtojais bus pripažinti dalyviai, surinkę daugiausia taškų.
                </p>
           </div>
           <div className="Etapai">
                <p>
                Konkurse norintys dalyvauti moksleiviai kviečiami registruotis iki balandžio 15 d. 23 val. 59 min. per elektroninę registracijos sistemą interneto svetainėje dzk.lrt.lt.<br/>
                <br/>
                <b>Pirmasis etapas</b> balandžio 16-17 d.<br/>
                <br/>
                <b>Finalinis etapas</b> gegužės 11 d.
                </p>
           </div>
        </div>
    );        
}
export default Info;